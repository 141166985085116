<template xmlns:el-col="http://www.w3.org/1999/html">
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> <span class="link" @click="$router.push('/client/version')">版本列表</span> \ 版本发布
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" :rules="rules" :model="form" label-width="100px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="版本号" prop="version">
                            <span v-if="form.state===1">{{form.version}}</span>
                            <el-input v-else class="form-item" v-model="form.version" placeholder="版本号"
                                      @change="getClient(false)"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否全量" prop="totalGas" required>
                            <span v-if="form.state===1">{{form.totalGas===1?'是':'否'}}</span>
                            <el-switch v-else
                                       v-model="form.totalGas"
                                    active-color="#13ce66"
                                    inactive-color="#999"
                                    :active-value="1"
                                    :inactive-value="0">
                            </el-switch>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="更新内容" prop="remark">
                            <span v-if="form.state===1">{{form.remark}}</span>
                            <el-input v-else type="textarea" class="form-item full" v-model="form.remark" placeholder="更新备注"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发布日期" prop="createDate">
                            <span v-if="form.state===1">{{form.createDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="更新日期" prop="updateDate">
                            <span v-if="form.state===1">{{form.updateDate.toDate("yyyy-MM-dd HH:mm:ss")}}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div style="font-weight:bold;background-color: #f5f7fa;padding: 10px 0;color: #909399;font-size: 14px;width: 100%;padding-left: 10px;height: 20px;margin-top: 5px;border: 1px solid #EEE">
                        <span>客户端</span>
                        <el-button v-if="hasAuth('SaveClientVersion','UpdateClientVersion')"
                                   type="text"
                                   icon="el-icon-refresh"
                                   class="refresh-btn"
                                   @click="()=>{form.state===1?getClient(true):getClient(true)}"
                        >刷新
                        </el-button>
                    </div>
                </el-row>
                <el-row>
                    <el-table
                            ref="table"
                            :data="tableData"
                            border
                            class="table"
                            header-cell-class-name="table-header"
                    >
                        <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                        <el-table-column prop="name" label="名称" align="center"></el-table-column>
                        <el-table-column prop="mac" label="客户端MAC" align="center" width="160"></el-table-column>
                        <el-table-column prop="ip" label="客户端IP" align="center"></el-table-column>
                        <el-table-column label="心跳状态" align="center" width="180">
                            <template #default="scope">
                                <span v-if="scope.row.healthState===1" class="success">正常</span>
                                <span v-else-if="scope.row.type==='web'">无状态</span>
                                <span v-else class="error">离线</span>
                                <br/>
                                <span v-if="scope.row.refreshDate && scope.row.refreshDate>0">{{scope.row.refreshDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="version" label="当前版本" align="center">
                            <template #default="scope">
                                {{scope.row.version||'无版本'}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="process" label="更新结果" align="center" v-if="form.state===1">
                            <template #default="scope">
                                <div v-if="scope.row.process">
                                    <span v-if="scope.row.process.state===0">未开始</span>
                                    <span v-if="scope.row.process.state===1" class="success">更新完成</span>
                                    <span v-else-if="scope.row.process.state===-1" class="error">更新失败</span>
                                    <span v-else-if="scope.row.process.state===2">正在更新</span> ({{scope.row.process.process||0}}%)
                                    <div>{{scope.row.process.remark}}</div>
                                    <el-button v-if="scope.row.process.state===-1" type="text" @click="audit(scope.row.id)">重试</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <br/>
                <el-row v-if="key!=null && form.state===1">
                    <el-col :span="24">
                        <div style="text-align:left;background-color: #f5f7fa;padding: 14px;color: #909399;font-size: 14px;font-weight: bold;border: 1px solid #EEE">
                            更新日志
                        </div>
                        <ul ref="logs" class="scan-log" :style="{height:common.calcTableHeight(590)+'px'}">
                            <li v-for="(log,index) in logs" class="infinite-list-item" :key="index" :class="{error:log.level==='error'}">{{log.msg}}</li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-upload
                                class="upload-item"
                                drag
                                :disabled="form.state===1"
                                show-file-list
                                :file-list="form.versionPackage"
                                :limit="1"
                                :action="$store.state.serviceUrl+'/file/pre/upload'"
                                :headers="getUploadHeader"
                                :on-success="(res,file)=>{handleAvatarSuccess(res,file,'versionPackageId')}"
                                accept=".zip">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text" v-if="form.state===1">更新包</div>
                            <div class="el-upload__text" v-else>将代码更新包拖到此处，或<em>点击上传</em></div>
                            <template #tip>
                                <div class="el-upload__tip">更新包:只能上传 zip 压缩文件，且不超过 100MB</div>
                            </template>
                        </el-upload>
                    </el-col>
                </el-row>
                <br/>
                <el-row>
                    <el-col :span="24" v-if="form.state===0">
                        <div style="text-align: right">
                            <el-button v-if="hasAuth('SaveClientVersion','UpdateClientVersion')"
                                       type="primary"
                                       icon="el-icon-upload"
                                       @click="saveEdit()"
                            >保存
                            </el-button>
                            <el-button  v-if="hasAuth('AuditClientVersion') && form.id"
                                       type="success"
                                       icon="el-icon-success"
                                       @click="audit()"
                            >确认并更新
                            </el-button>
                        </div>
                    </el-col>
                </el-row>

            </el-form>
        </div>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";
    import api from "../../utils/api";

    export default {
        name: "ClientUpdate",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                pageTotal: 0,
                form: {},
                loading: false,
                clients: [],
                client: {id: null},
                logs: [],
                rules: {
                    version: [{required: true, message: "请填写版本号", trigger: "blur"}],
                    versionPackage: [{required: true, message: "还未上传更新包", trigger: "blur"}],
                },
                key:null,
                updateDone:[false],
                //询问消息显示时间,需要割开时间显示
                askTime:0,
            };
        },
        created() {
            this.init();
        },
        methods: {
            init(){
                this.client.id = this.$route.query.clientId;
                this.key = this.$route.query.key;
                if (this.client.id) {
                    this.client.id = parseInt(this.client.id);
                }
                this.form.id = this.$route.query.id;
                if(this.form.id){
                    //id不为空时
                    this.getData(this.form.id);
                }else{
                    this.getClient();
                    this.form.state=0;
                }
            },
            //获取数据
            getData(id){
                http.post("/client/version/get", {id: id}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.form = data.data;
                        if(!this.form){
                            return;
                        }
                        if(this.form.versionPackage&&this.form.versionPackage.length>0){
                            let files = [];
                            for (let i = 0; i < this.form.versionPackage.length; i++) {
                                files.push(this.form.versionPackage[i].fid);
                            }
                            this.form.versionPackageId = files.join(",");
                        }
                        this.getClient();
                        if(this.form.state===1){
                            if(this.key){
                                this.updateDone[0] = false;
                                this.$nextTick(function () {
                                    this.logProcess();
                                });
                            }
                        }
                        return;
                    }
                    this.$message.error(data.msg);
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取客户端列表
            getClient(force,refresh) {
                if (!this.form.version&&force) {
                    this.$message.error("请填写版本号");
                }
                let param = {page: 1, rows: 100};
                if(this.form.state===1){
                    param.versionId = this.form.id;
                }else{
                    param.state=1
                }
                http.post("/client/list",param ,{animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        if(refresh){
                            for (let i = 0; i < data.rows.length; i++) {
                                let row = data.rows[i];
                                let obj = common.arrayFindReturnObj(this.tableData,row.id);
                                obj.version = row.version;
                            }
                        }else{
                            this.tableData = data.rows;
                            this.pageTotal = data.total;
                        }
                        if (this.tableData.length>0) {
                            this.getClientVersionChild();
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取版本结果
            getClientVersionChild(){
                http.post("/client/version/get/child", {id: this.form.id},{animation: store.state.Animation.NONE}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        data = data.data;
                        if(data){
                            let done = true;
                            for (let i = 0; i < data.length; i++) {
                                if(data[i].state!==2){
                                    let obj =common.arrayFindReturnObj(this.tableData,data[i].clientId);
                                    if(obj){
                                        obj.process = data[i];
                                    }
                                }else{
                                    done = false;
                                }
                            }
                            this.updateDone[0] = done;
                        }
                    }
                });
            },
            // 保存编辑
            saveEdit() {
                //获取选中客户端
                this.$refs.form.validate(valid => {
                    if (valid) {
                        http.post(this.form.id ? '/client/version/update' : '/client/version/save', this.form, {alertError: true, headers: {"Content-Type": 'application/json;charset=utf-8'},}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if(!this.form.id){
                                    this.$router.push({path:'/client/version/edit',query:{id:data.data.id}})
                                    return;
                                }
                                this.getData(data.data.id);
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    }
                });

            },
            // 确认
            audit(clientId) {
                this.$confirm(clientId!=null?"确定要重新尝试更新操作吗":"确定后开始更新不能再变更,确认要继续吗？", "提示", {
                    type: "warning",
                })
                    .then(() => {
                        http.post('/client/version/audit', {id:this.form.id,clientId:clientId,key:this.key}, {alertError: true}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success(`操作成功`);
                                if(!this.key||this.key!==data.data){
                                    this.$router.push({path:'/client/version/edit',query:{id:this.form.id,key:data.data}})
                                }else{
                                    this.init();
                                }
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            //日志进度
            logProcess(){
                api.getTaskLog(this.key,this.logs,this.$refs.logs,(data)=>{
                    if(data.state===3){
                        let time = +new Date();
                        if(this.askTime+500>time){
                            this.askTime += 500;
                        }else{
                            this.askTime = time;
                        }
                        setTimeout(()=>{
                            let close = false;
                            let notify = this.$notify({
                                duration: 10000,
                                title: '来自客户端的询问(10秒后自动取消)',
                                dangerouslyUseHTMLString: true,
                                message: data.msg + '<br/><b>单击确认</b>',
                                onClose: () => {
                                    if(!close){
                                        this.$message.success(`已取消`);
                                        http.post('/client/task/wait/ask/update',
                                            {id: data.key,state: 0},
                                            {animation: store.state.Animation.NONE});
                                    }
                                },
                                onClick: () => {
                                    this.$message.success(`已确认`);
                                    http.post('/client/task/wait/ask/update',
                                        {id: data.key,state: 1},
                                        {animation: store.state.Animation.NONE});
                                    close = true;
                                    notify.close();
                                }
                            });
                        },this.askTime-time);
                    }
                    if(data.clientId){
                        let row = common.arrayFindReturnObj(this.tableData,data.clientId);
                        if(row){
                            row.process = data;
                        }
                    }
                },()=>{
                    let interval = setInterval(()=>{
                        this.getClient(null,true);
                        if(this.updateDone[0]){
                            setTimeout(()=>{
                                this.getClient(null,true);
                            },2000);
                            clearInterval(interval);
                        }
                    },5000);

                },this.updateDone);
            },
            //上传完成
            handleAvatarSuccess(res, file, type) {
                if (res.code === this.$store.state.ResultCode.OK) {
                    this.form[type] = res.data[0];
                } else {
                    file.status = 'error';
                    this.$message.error(res.msg);
                }
            },
        },
        computed: {
            //获取上传请求头
            getUploadHeader() {
                return {tk: localStorage.getItem('tk')}
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "EditClientVersion") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .container {
        padding: 25px;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }

    .scan-log {
        width: 100%;
        border: 1px solid #EEE;
        overflow: auto;
    }

    .refresh-btn {
        margin: 0 10px;
        min-height: auto;
        height: 20px;
        padding: 0;
    }

    .upload-item {
        width: 360px;
        display: inline-block;
        margin-left: 10px;
        float: left;
    }
</style>
